.mainLogo {
    background-image: url(./assets/logo2.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 250px;
    height: 90px;
    margin-bottom: 1rem;
}

.left {
    max-width: 500px;
    color: #fff;
}

.center a {
    color: #fff !important;
}

.center a:hover, .twitter a:hover, .instagram a:hover, .discord a:hover {
    color: #b250ff !important;
    transition: 0.3s ease;
}

.quickLinks {
    background-image: url(./assets/textOne.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 200px;
    height: 90px;
}

.myFooter .instagram, .myFooter .discord {
    margin-top: 1rem;
}

.twitter a, .instagram a, .discord a {
    margin-left: 3rem;
    color: white;
}

.follow {
    background-image: url(./assets/textTwo.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 175px;
    height: 90px;
}