html {
    background: #000;
}

.purple {
    color: #b250ff;
}

.myBox {
    height: 370px;
}

.mint {
    margin-top: -0.7rem;
}

.myStyle3 {
    text-align: left;
    max-width: 550px;
}

.myBox {
    background-color: rgba(255,255,255,0.05);
    border-radius: 8px;
    padding: 1rem;
}

.mintAmount {
    padding: 0.7rem 1rem;
    margin: 0rem 1.5rem;
    background-color: rgba(255,255,255,0.05);
    width: 500px;
    border-radius: 6px;
}

.myButton {
    background: transparent;
    outline: none;
    border: none;
    color: #B14FFd;
    cursor: pointer;
    margin-top: -0.5rem;
}

.button:hover {
    border-color: transparent !important;
}

.myButton:hover {
    border-color: transparent !important;
}

.button.purple-nooutline:hover {
    border-color: #B14FFd !important;
    color: #fff !important;
}

/* timer */
.mydate {
    color: #fff;
    font-weight: bold;
    font-size: 3rem;
}

.timer span {
    font-size: 1.2rem;
    font-weight: 700;
    color: #b250ff;
    margin-bottom: 0.7rem;
    margin-left: 0.3rem;
}

/* edit to button */
button.tattyEdit {
    color: #fff !important;
    border-color: transparent !important;
}
button.tattyEdit:hover {
    background-color: transparent !important;
}