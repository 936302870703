.navbar {
    background: transparent !important;
}

.myStyle {
    font-family: another_danger_slanted_-_deRg; 
    font-weight: 300;
    letter-spacing: 2px;
}

.myStyle2 {
    font-size: 0.9rem;
    color: #b250ff;
    font-weight: 50;
}

.navLogo {
    background-image: url(assets/logo.png);
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
}

.navbar-burger span {
    background-color: #fff !important;
}

.navbar-menu {
    background-color: #000;
}

/* nav */
.navbar {
    border: 1px solid #b250ff;
    border-top: 0px;
    border-radius: 0px 0px 40px 40px;
}

.navbar-item img {
    max-height: 3rem !important;
}

.link {
    color: #fff !important;
    font-style: italic;
    font-size: 1rem;
}

.link:hover {
    color: #b250ff !important;
    transition: 0.3s ease;
}

.icon {
    max-height: 17px !important; 
}

.discord {
    background-image: url(assets/discord.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.twitter {
    background-image: url(assets/twitter.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.instagram {
    background-image: url(assets/instagram.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.button {
    border-radius: 0px !important;
    padding: 1rem 1.5rem !important;
}

.button.purple-nooutline {
    background-color: #B14FFD;
    border: 1px solid #B14FFD;
    border-radius: 5px;
    color: #fff;
}

.button.button.purple-nooutline:hover {
    background-color: transparent;
    transition: 0.3s ease;
}

.button.purple-outline {
    background-color: transparent;
    border: 1px solid #B14FFD;
    border-radius: 5px;
    color: #fff;
}

.button.purple-outline:hover {
    background-color: #B14FFD;
    transition: 0.3s ease;
}

.button.green {
    background-color: #17B410;
    border: none;
    color: #fff;
}

.button.green:hover {
    background-color: #fff;
    color: #000 !important;
    transition: 0.3s ease;
}

a.purple:hover {
    color: #b250ff !important;
}