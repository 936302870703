/* media queries */
@media only screen and (max-width: 1800px) {
    .header {
        height: 80vh;
    }
}

@media only screen and (max-width: 1415px) {
    .header {
        height: 65vh;
    }
    .mintAmount {
        width: 400px;
    }
    .mission img {
        max-width: 40%;
    }
}

@media only screen and (max-width: 1215px) {
    .navbar p {
        font-size: 0.4rem !important;
    }
    .mintAmount {
        max-width: 300px;
    }
    .newCenter {
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
    }
    .newCenter p {
        margin: 0rem auto;
        margin-top: 2rem;
    }
    .newCenter a {
        margin: 0 auto;
    }
    .myStyle3 {
        margin: 1rem 3rem !important;
        text-align: center !important;
    }
    .newOne {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        max-width: 80vw;
        margin: 0 auto;
    }
    .text-area {
        align-items: center !important;
    }
    .myCenter {
        justify-content: center;
    }
    .images {
        margin-right: unset !important;
        /* margin-left: 5rem;  */
    }
    .images div {
        margin: 1rem 0rem !important;
        align-self: center !important;
    }
    .mouse1, .mouse2, .mouse3, .mouse4 {
        width: 400px !important;
        height: 400px !important;
        background-size: cover !important;
    }
}

@media only screen and (max-width: 1100px) {
    .header {
        height: 50vh;
    }
    .images div {
        margin-left: 0.8rem !important;
    }
    .mouse1, .mouse2, .mouse3, .mouse4 {
        width: 300px !important;
        height: 300px !important;
        background-size: cover !important;
        margin: 0rem !important;
    }
}

@media only screen and (max-width: 1023px) {
    .navbar-end .icons {
        margin: 0.8rem !important;
    }
    .navbar-end .button {
        margin: 0.8rem !important;
    }
    .navbar-end .myStyle2 {
        margin: 0.8rem !important;
    }
}

@media only screen and (max-width: 900px) {
    .header {
        height: 40vh;
    }
    .mobileCenter {
        justify-content: flex-start !important;
    }
    .mobileCenter .right {
        width: 100% !important;
        justify-content: flex-start !important;
    }
    .mobileCenter .right img {
        max-width: 50%;
    }
    .images div {
        margin: 0 auto;
        align-self: center !important;
    }
    .mouse1, .mouse2, .mouse3, .mouse4 {
        width: 40vw !important;
        height: 40vw !important;
        background-size: cover !important;
        margin: 0rem !important;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        height: 30vh;
    }
}