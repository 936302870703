/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 31, 2022 */

@font-face {
    font-family: 'another_danger_-_demoregular';
    src: url('./assets/fonts/another_danger_-_demo-webfont.woff2') format('woff2'),
         url('./assets/fonts/another_danger_-_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'another_danger_slanted_-_deRg';
    src: url('./assets/fonts/another_danger_slanted_-_demo-webfont.woff2') format('woff2'),
         url('./assets/fonts/another_danger_slanted_-_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}