html, body {
    background: #000;
    height: 100%;
}

body {
    background: #000000;
    font-family: 'Montserrat';
}

.header {
    background-image: url(assets/header.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 90vh;
    width: 100vw;
}

@media only screen and (max-width: 1800px) {
    .header {
        height: 80vh;
    }
}

@media only screen and (max-width: 1415px) {
    .header {
        height: 65vh;
    }
}

@media only screen and (max-width: 1100px) {
    .header {
        height: 50vh;
    }
}

@media only screen and (max-width: 900px) {
    .header {
        height: 40vh;
    }
}