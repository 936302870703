.mainClass {
    margin-top: -0.7rem;
}

.mainText {
    max-width: 550px;
}

.images {
    max-width: 50%;
    margin-right: -10rem;
}

.mission {
    margin-top: 8rem;
}

.mouse1 {
    background-image: url(./assets/1.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 250px;
    height: 250px;
}
.mouse2 {
    background-image: url(./assets/2.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 250px;
    height: 250px;
}
.mouse3 {
    background-image: url(./assets/3.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 250px;
    height: 250px;
}
.mouse4 {
    background-image: url(./assets/4.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 250px;
    height: 250px;
}

